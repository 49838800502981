<template>
  <div class="youth-attendance" v-if="hasPerm('youth.view_seanceinscription')">
    <div ref="printMe">
      <page-header
        :title="'Fréquentation ' + youthHomeLabel"
        icon="fas fa-chart-bar"
        :links="getLinks()"
        :print-hour="true"
      >
      </page-header>
      <div ref="excelMe">
        <div class="youth-attendance" v-for="blockId of blockIds" :key="blockId">
          <b-row>
            <b-col>
              <div class="section">
                  <youth-attendance-table :id="'' + blockId"></youth-attendance-table>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import PageHeader from '@/components/Layout/PageHeader'
import YouthAttendanceTable from '@/components/Stats/YouthAttendanceTable'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import { openDocument } from '@/utils/http'

export default {
  name: 'youth-home-stats',
  mixins: [BackendMixin],
  components: {
    YouthAttendanceTable,
    PageHeader,
  },
  props: {
  },
  data() {
    return {
      statsLoading: 'youth-attendance',
      items: [],
      blockIds: [1],
    }
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    getLinks() {
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/'
      const docSlug = 'frequentations-' + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent, 'dossier_caf')
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'frequentations-' + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped lang="less">
table {
  width: 100%;
}
table tr td,
table tr th {
   border: solid 1px #f0f0f0;
   padding: 4px;
}
.sub-title {
  margin-bottom: 10px;
  font-weight: bold;
}
.section {
  padding: 5px;
  border: solid 1px #888;
  margin-bottom: 20px;
}
.block-link {
  text-align: right;
  margin-bottom: 5px;
}
</style>
